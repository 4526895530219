import BasicFormComponent from '../../../../components/basicFormComponent/BasicFormComponent';
import React from 'react';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';
import axios from 'axios';
import moment from 'moment';

import './ContractProductQuantityModal.scss';

import TextField from '../../../../components/form/TextField';
import CheckBox from '../../../../components/form/CheckBox';

import { checkNotEmptyText, checkValidDate } from '../../../../helpers/validator/Validator';

import Modal from '../../../../components/modal/Modal';
import Loader from '../../../../components/basic/Loader';

class ContractProductQuantityModal extends BasicFormComponent {
    TObject = appTranslate.areas.contracts.contract.productCountModal;

    constructor(props) {
        super(props);

        this.state = {
            products: [],
            searchValue: '',
            searchResults: [],
            searchRequest: false,
            form: {},
            loader: true
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    search = () => {};

    fetchData = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/contract/contractProduct/list/' + this.props.params.id,
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            const data = response.data.data;
                            this.setLoader(false);
                            this.setState(prevState => {
                                return {
                                    products: data.map(d => ({
                                        ...d,
                                        quantity: { ...d.quantity, new: d.quantity.contract }
                                    }))
                                };
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    prepareData = () => {
        return this.state.products.map(p => ({ productId: p.productId, quantity: p.quantity.new }));
    };

    onSubmit = () => {
        console.log(this.prepareData());
        this.submit();
        // if (this.validateFields()) {
        //     this.submit();
        // }
    };

    submit = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/contract/contractProduct/update',
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        contractId: this.props.params.id,
                        quantity: this.prepareData()
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    onSearchInput = value => {
        this.setState(prev => ({ searchValue: value }));
    };

    onSearchInputKeyPress = event => {
        if (event.key === 'Enter') {
            this.onSearchSearch();
        }
    };

    onSearchClear = () => {
        this.setState(prev => ({ searchValue: '', searchResults: [], searchRequest: false }));
    };

    onSearchSearch = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/catalogue/products/product/search/' + this.state.searchValue,
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            const data = response.data.data;
                            this.setLoader(false);
                            this.setState(prevState => {
                                return {
                                    searchResults: data,
                                    searchRequest: true
                                };
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    onQuantityChange = (productId, quantity) => {
        if (quantity !== '') {
            quantity = parseInt(quantity);
            if (!Number.isInteger(quantity)) {
                return;
            }

            if (quantity < 0) {
                quantity = quantity * -1;
            }
        }
        this.setState(prev => ({
            products: prev.products.map(p =>
                p.productId === productId ? { ...p, quantity: { ...p.quantity, new: quantity } } : p
            )
        }));
    };

    onQuantityBlur = productId => {
        this.setState(prev => ({
            products: prev.products.map(p =>
                p.productId === productId && (p.quantity.new === '' || p.quantity.new < p.quantity.used)
                    ? { ...p, quantity: { ...p.quantity, new: p.quantity.used } }
                    : p
            )
        }));
    };

    onProductRemove = productId => {
        const index = this.state.products.findIndex(e => e.productId === productId);

        if (index === -1) {
            return;
        }

        const product = this.state.products[index];

        if (product.quantity.used > 0) {
            return;
        }

        this.setState(prev => {
            prev.products.splice(index, 1);
            return {
                products: prev.products
            };
        });
    };

    onProductAdd = p => {
        const product = {
            productId: p.productId,
            ean: p.ean,
            productName: p.productName,
            category: {
                label: p.categoryLabel
            },
            mainCategory: {
                label: p.mainCategoryLabel
            },
            quantity: {
                contract: 0,
                used: 0,
                left: 0,
                new: 0
            }
        };

        this.setState(prev => ({ products: [...prev.products, product] }));
    };

    render() {
        const footer = (
            <div className='space-between'>
                <button className='_button-light' onClick={() => this.onSubmit()}>
                    {this.T('save')}
                </button>

                <button className='_button-light' onClick={() => this.props.close(false)}>
                    {this.T('cancel')}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader) {
            loader = <Loader />;
        }

        const content = (
            <div>
                {loader}

                <div className='_contract-product-query-modal__products__wrapper'>
                    {this.state.products.map(p => (
                        <div className='_contract-product-query-modal__product__wrapper' key={p.productId}>
                            <div className='_contract-product-query-modal__product__info'>
                                <div className='_contract-product-query-modal__product__info-name'>
                                    {this.T(p.productName)}
                                </div>
                                <div className='_contract-product-query-modal__product__info-ean'>{p.ean}</div>
                                <div className='_contract-product-query-modal__product__info-category'>
                                    {this.T(p.mainCategory.label)} > {this.T(p.category.label)}
                                </div>
                            </div>
                            <div className='_contract-product-query-modal__product__quantity'>
                                <table className='_contract-product-query-modal__product__quantity-table'>
                                    <thead>
                                        <tr>
                                            <td />
                                            <td>{this.T('quantity.contract')}</td>
                                            <td>{this.T('quantity.used')}</td>
                                            <td>{this.T('quantity.left')}</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.T('quantity.before')}</td>
                                            <td>{p.quantity.contract}</td>
                                            <td>{p.quantity.used}</td>
                                            <td>{p.quantity.left}</td>
                                        </tr>
                                        <tr>
                                            <td>{this.T('quantity.after')}</td>
                                            <td>
                                                {' '}
                                                <input
                                                    type='text'
                                                    value={p.quantity.new}
                                                    onChange={event =>
                                                        this.onQuantityChange(p.productId, event.target.value)
                                                    }
                                                    onBlur={() => this.onQuantityBlur(p.productId)}
                                                />
                                            </td>
                                            <td>{p.quantity.used}</td>
                                            <td>{p.quantity.new >= 0 ? p.quantity.new - p.quantity.used : ''}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='_contract-product-query-modal__product__remove-button'>
                                {p.quantity.used === 0 ? (
                                    <button onClick={() => this.onProductRemove(p.productId)}>close</button>
                                ) : null}
                            </div>
                        </div>
                    ))}
                    {this.state.products.length === 0 ? (
                        <div className='_contract-product-query-modal__products__empty'>{this.T('productEmpty')}</div>
                    ) : null}
                </div>

                <div className='_contract-product-query-modal__search-field__wrapper'>
                    <input
                        className='_contract-product-query-modal__search-field__input'
                        type='text'
                        value={this.state.searchValue}
                        onChange={event => this.onSearchInput(event.target.value)}
                        onKeyPress={event => this.onSearchInputKeyPress(event)}
                    />
                    <button
                        className='_contract-product-query-modal__search-field__button-search'
                        onClick={() => this.onSearchSearch()}
                    >
                        {this.T('searchButton')}
                    </button>
                    <button
                        className='_contract-product-query-modal__search-field__button-clear'
                        onClick={() => this.onSearchClear()}
                    >
                        {this.T('clearButton')}
                    </button>
                </div>
                <div className='_contract-product-query-modal__search-results__wrapper'>
                    {this.state.searchResults.map(p => (
                        <div className='_contract-product-query-modal__search-result' key={p.productId}>
                            <div className='_contract-product-query-modal__search-result__info'>
                                <div className='_contract-product-query-modal__search-result__info-name'>
                                    {this.T(p.productName)}
                                </div>
                                <div className='_contract-product-query-modal__search-result__info-ean'>{p.ean}</div>
                                <div className='_contract-product-query-modal__search-result__info-category'>
                                    {this.T(p.mainCategoryLabel)} > {this.T(p.categoryLabel)}
                                </div>
                            </div>

                            <div className='_contract-product-query-modal__search-result__buttons'>
                                {this.state.products.findIndex(e => e.productId === p.productId) === -1 ? (
                                    <button onClick={() => this.onProductAdd(p)}>add</button>
                                ) : null}
                            </div>
                        </div>
                    ))}
                    {this.state.searchRequest && this.state.searchResults.length === 0 ? (
                        <div className='_contract-product-query-modal__search-results__empty'>
                            {this.T('resultsEmpty')}
                        </div>
                    ) : null}
                    {!this.state.searchRequest ? (
                        <div className='_contract-product-query-modal__search-results__empty'>
                            {this.T('searchToAdd')}
                        </div>
                    ) : null}
                </div>
            </div>
        );
        return (
            <Modal
                title={this.T('title')}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default ContractProductQuantityModal;
