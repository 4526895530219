import React, { Component } from 'react';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';
import { AppContext } from '../../../../services/context';
import axios from 'axios';

import { debounce } from 'lodash';

import { emptyObject } from '../../../../services/translateService';

import Loader from '../../../../components/basic/Loader';

import TextField from '../../../../components/form/TextField';
import TextFieldL from '../../../../components/form/TextFieldL';
import TextAreaL from '../../../../components/form/TextAreaL';
import CheckBox from '../../../../components/form/CheckBox';
import SelectWithButton from '../../../../components/form/SelectWithButton';
import SelectField from '../../../../components/form/SelectField';

import Modal from '../../../../components/modal/Modal';

class ProductEditModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            properties: [],
            categories: [],
            types: [],
            product: {},
            form: {
                ean: {
                    value: '',
                    error: '',
                    check: true
                },
                isVisible: true,
                isActive: true,
                isExpiration: false,
                category: -1,
                type: -1
            },
            loader: true,
            loaders: {
                types: true,
                properties: true,
                categories: true,
                product: true,
                loadDetails: true
            }
        };
    }

    componentDidMount() {
        this.fetchProperties();
        this.fetchTypes();
        this.fetchCategories();
        this.fetchProduct();
    }

    fetchProperties = () => {
        this.setLoader('properties', true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/catalogue/products/propertyDefinition/tree',
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        label: this.state.form.label,
                        isVisible: this.state.form.isVisible
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.prepareProperties(response.data.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    fetchTypes = () => {
        this.setLoader('types', true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/catalogue/products/product/types/list',
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200 && response.data.data.length > 0) {
                            this.setState(prevState => {
                                return {
                                    types: response.data.data,
                                    form: {
                                        ...prevState.form,
                                        type: response.data.data[0].productTypeId
                                    }
                                };
                            });
                            this.setLoader('types', false);
                        } else {
                            return Promise.reject('Invalid data');
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('types', false);
            });
    };

    fetchCategories = () => {
        this.setLoader('categories', true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/catalogue/category/tree',
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200 && response.data.data.length > 0) {
                            this.setState(prevState => {
                                return {
                                    categories: response.data.data,
                                    form: {
                                        ...prevState.form,
                                        category: response.data.data[0].categoryId
                                    }
                                };
                            });
                            this.setLoader('categories', false);
                        } else {
                            return Promise.reject('Invalid data');
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('categories', false);
            });
    };

    fetchProduct = () => {
        this.setLoader('product', true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/catalogue/products/product/details/' + this.props.productId,
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200 && response.data.data) {
                            this.setState(prevState => {
                                return {
                                    product: response.data.data
                                };
                            });
                            this.setLoader('product', false);
                        } else {
                            return Promise.reject('Invalid data');
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('categories', false);
            });
    };

    loadDetailsToForm = () => {
        if (
            !this.state.loaders.categories &&
            !this.state.loaders.types &&
            !this.state.loaders.product &&
            !this.state.loaders.properties
        ) {
            this.setState(
                prevState => {
                    return {
                        form: {
                            ...prevState.form,
                            category: prevState.product.category.categoryId,
                            type: prevState.product.productType.productTypeId,
                            ean: {
                                ...prevState.ean,
                                value: prevState.product.ean
                            },
                            isVisible: prevState.product.isVisible,
                            isActive: prevState.product.isActive,
                            isExpiration: prevState.product.isExpiration
                        },
                        properties: prevState.properties.map(pg => {
                            return {
                                ...pg,
                                properties: pg.properties.map(p => {
                                    let value = { ...emptyObject('') };
                                    let show = p.show;

                                    prevState.product.propertyGroups.forEach(ppg => {
                                        if (ppg.productPropertiesGroupId !== pg.productPropertiesGroupId) {
                                            return;
                                        }

                                        ppg.properties.forEach(pp => {
                                            if (
                                                pp.productPropertyDefinition.productPropertyDefinitionId !==
                                                p.productPropertyDefinitionId
                                            ) {
                                                return;
                                            }

                                            show = true;
                                            value = {
                                                ...pp.content,
                                                error: ''
                                            };
                                        });
                                    });

                                    return {
                                        ...p,
                                        value: value,
                                        show: show
                                    };
                                })
                            };
                        })
                    };
                },
                () => {
                    this.setLoader('loadDetails', false);
                }
            );
        }
    };

    prepareProperties = properties => {
        properties.forEach(pg => {
            pg.properties.forEach(p => {
                p.value = { ...emptyObject(''), check: true };
                p.show = p.isRequired ? true : false;
                p.error = '';
            });
        });

        this.setState(
            prevState => {
                return {
                    properties: properties
                };
            },
            () => this.setLoader('properties', false)
        );
    };

    T = (prop, params) => {
        let p;

        if (typeof prop === 'object') {
            p = prop;
        }
        if (typeof prop === 'string') {
            p = prop.split('.').reduce((obj, a) => obj[a], appTranslate.areas.catalogue.product.edit);
        }

        return this.context.translateService.getTranslation(p, params);
    };

    setValue = (propName, value, lang) => {
        // this.checkField(propName);
        if (
            !lang &&
            typeof this.state.form[propName] === 'object' &&
            this.state.form[propName].hasOwnProperty('value')
        ) {
            lang = 'value';
        }
        this.setState(prevState => {
            if (lang) {
                return {
                    form: {
                        ...prevState.form,
                        [propName]: {
                            ...prevState.form[propName],
                            [lang]: value
                        }
                    }
                };
            } else {
                return {
                    form: {
                        ...prevState.form,
                        [propName]: value
                    }
                };
            }
        });
    };

    checkField = debounce(propName => {
        this.checkFieldByName(propName);
    }, 600);

    checkFieldByName = propName => {
        let error = '';
        if (!this.state.form[propName].check) {
            return true;
        }

        if (this.state.form[propName].hasOwnProperty('value')) {
            console.log('has value');
            if (this.state.form[propName].value.length < 1) {
                error = this.T('emptyField', ['ean']);
            }
        } else {
            this.context.translateService.languages.forEach(l => {
                if (this.state.form[propName][l].length === 0) {
                    error += this.T('emptyField', [l]);
                }
            });
        }

        this.setState(prevState => {
            return {
                form: {
                    ...prevState.form,
                    [propName]: {
                        ...prevState.form[propName],
                        error: error
                    }
                }
            };
        });
        return error.length === 0;
    };

    checkDynamicFields = () => {
        let check = true;

        this.setState(prevState => {
            return {
                properties: prevState.properties.map(pg => {
                    return {
                        ...pg,
                        properties: pg.properties.map(p => {
                            let error = '';
                            if (p.show) {
                                this.context.translateService.languages.forEach(l => {
                                    if (p.value[l].length < 1) {
                                        error += this.T('emptyField', [l]);
                                        check = false;
                                    }
                                });
                            }

                            return {
                                ...p,
                                value: {
                                    ...p.value,
                                    error: error
                                }
                            };
                        })
                    };
                })
            };
        });

        return check;
    };

    onSubmit = () => {
        if (this.checkFields()) {
            this.submit();
        }
    };

    setLoaderState = state => {
        this.setState({ loader: state });
    };

    setLoader = (loader, state) => {
        this.setState(
            prevState => {
                return {
                    loaders: {
                        ...prevState.loaders,
                        [loader]: state
                    }
                };
            },
            () => {
                if (loader !== 'loadDetails') {
                    this.loadDetailsToForm();
                }
            }
        );
    };

    checkFields = () => {
        const checkEan = this.checkFieldByName('ean');
        const checkDynamicFields = this.checkDynamicFields();
        return checkEan && checkDynamicFields;
    };

    submit = () => {
        this.setLoader('properties', true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/catalogue/products/product/update',
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        product: this.prepareData()
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    onBlur = propName => {
        this.checkField(propName);
    };

    close = result => {
        this.props.close(result);
    };

    onInputProperties = (value, lang, pgI, pI) => {
        this.setState(prevState => {
            return {
                properties: prevState.properties.map((pg, pgIndex) => {
                    if (pgIndex === pgI) {
                        return {
                            ...pg,
                            properties: pg.properties.map((p, pIndex) => {
                                if (pIndex === pI) {
                                    return {
                                        ...p,
                                        value: {
                                            ...p.value,
                                            [lang]: value
                                        }
                                    };
                                }
                                return p;
                            })
                        };
                    }

                    return pg;
                })
            };
        });
    };

    showProperty = (pgI, productPropertyDefinitionId, state) => {
        productPropertyDefinitionId = parseInt(productPropertyDefinitionId);

        this.setState(prevState => {
            return {
                properties: prevState.properties.map((pg, pgIndex) => {
                    if (pgIndex === pgI) {
                        return {
                            ...pg,
                            properties: pg.properties.map(p => {
                                if (p.productPropertyDefinitionId === productPropertyDefinitionId) {
                                    return {
                                        ...p,
                                        show: state,
                                        value: {
                                            ...emptyObject(''),
                                            error: ''
                                        }
                                    };
                                }
                                return p;
                            })
                        };
                    }

                    return pg;
                })
            };
        });
    };

    prepareData = () => {
        let properties = [];

        this.state.properties.forEach(pg => {
            pg.properties.forEach(p => {
                if (p.show) {
                    properties.push({
                        productPropertyDefinition: {
                            productPropertyDefinitionId: p.productPropertyDefinitionId
                        },
                        content: p.value
                    });
                }
            });
        });
        let data = {
            productId: parseInt(this.props.productId),
            ean: this.state.form.ean.value,
            categoryId: parseInt(this.state.form.category),
            productTypeId: parseInt(this.state.form.type),
            isActive: this.state.form.isActive,
            isVisible: this.state.form.isVisible,
            isExpiration: this.state.form.isExpiration,
            properties: properties
        };

        console.log(data);

        return data;
    };

    render() {
        const footer = (
            <div className='space-between'>
                <button className='_button-light' onClick={() => this.onSubmit()}>
                    {this.T('save')}
                </button>

                <button className='_button-light' onClick={() => this.close()}>
                    {this.T('cancel')}
                </button>
            </div>
        );

        let content = <Loader />;

        if (
            !this.state.loaders.categories &&
            !this.state.loaders.properties &&
            !this.state.loaders.types &&
            !this.state.loaders.product &&
            !this.state.loaders.loadDetails
        ) {
            let properties = <div />;

            if (this.state.properties.length > 0) {
                properties = this.state.properties.map((pg, pgI) => {
                    let childs = <div key={pgI} />;

                    if (pg.properties.length > 0) {
                        childs = pg.properties.map((p, pI) => {
                            if (p.show) {
                                if (p.productPropertyDefinitionTypeId === 'TextLong') {
                                    return (
                                        <TextAreaL
                                            key={pI}
                                            field={p.value}
                                            onInput={(value, l) => this.onInputProperties(value, l, pgI, pI)}
                                            label={this.T(p.label)}
                                            isDeletable={!p.isRequired}
                                            onDelete={() =>
                                                this.showProperty(pgI, p.productPropertyDefinitionId, false)
                                            }
                                        />
                                    );
                                }

                                return (
                                    <TextFieldL
                                        key={pI}
                                        field={p.value}
                                        onInput={(value, l) => this.onInputProperties(value, l, pgI, pI)}
                                        label={this.T(p.label)}
                                        isDeletable={!p.isRequired}
                                        onDelete={() => this.showProperty(pgI, p.productPropertyDefinitionId, false)}
                                    />
                                );
                            }

                            return <div key={pI} />;
                        });
                    }

                    let unusedPropertiesAsOption = pg.properties.reduce((t, p) => {
                        if (!p.show) {
                            t.push({
                                value: p.productPropertyDefinitionId,
                                label: this.T(p.label)
                            });
                        }
                        return t;
                    }, []);

                    let addProperty = <div />;

                    if (unusedPropertiesAsOption.length > 0) {
                        addProperty = (
                            <SelectWithButton
                                options={unusedPropertiesAsOption}
                                onSubmit={value => this.showProperty(pgI, value, true)}
                                buttonLabel={this.T('addProperty')}
                            />
                        );
                    }

                    return (
                        <div key={pgI}>
                            <div className='modal-row'>
                                <h3>{this.T(pg.label)}</h3>
                            </div>
                            {childs}
                            {addProperty}
                        </div>
                    );
                });
            }

            content = (
                <div>
                    <TextField
                        label={this.T('ean')}
                        field={this.state.form.ean}
                        onInput={value => this.setValue('ean', value)}
                        error={this.state.form.ean.error}
                    />

                    <SelectField
                        label={'kategoria'}
                        value={this.state.form.category}
                        options={this.state.categories.map(c => {
                            return {
                                value: c.categoryId,
                                label: this.T(c.label),
                                options: c.categories.map(cc => {
                                    return {
                                        value: cc.categoryId,
                                        label: this.T(cc.label)
                                    };
                                })
                            };
                        })}
                        onInput={value => this.setValue('category', value)}
                    />

                    <SelectField
                        label={'typ'}
                        value={this.state.form.type}
                        options={this.state.types.map(e => {
                            return {
                                value: e.productTypeId,
                                label: this.T(e.label)
                            };
                        })}
                        onInput={value => this.setValue('type', value)}
                    />

                    <CheckBox
                        value={this.state.form.isVisible}
                        onInput={value => this.setValue('isVisible', value)}
                        label={this.T('visible')}
                    />
                    <CheckBox
                        value={this.state.form.isActive}
                        onInput={value => this.setValue('isActive', value)}
                        label={this.T('active')}
                    />
                    <CheckBox
                        value={this.state.form.isExpiration}
                        onInput={value => this.setValue('isExpiration', value)}
                        label={this.T('expiration')}
                    />

                    {properties}
                </div>
            );
        }
        return (
            <Modal
                title={this.T('title')}
                close={result => this.close(result)}
                footer={footer}
                content={content}
                visible={this.props.visible}
            />
        );
    }
}
ProductEditModal.contextType = AppContext;

export default ProductEditModal;
