import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import appTranslate from '../../../appTranslate.json';

import { AppContext } from '../../../services/context';

class CatalogueSecondaryNav extends Component {
    render() {
        const match = this.props.parentProps.match;

        return (
            <nav className='nav-secondary'>
                <Link to={`${match.path}/categories`}>
                    {this.context.translateService.getTranslation(
                        appTranslate.areas.catalogue.secondaryNav.categories
                    )}
                </Link>

                <Link to={`${match.path}/property`}>
                    {this.context.translateService.getTranslation(
                        appTranslate.areas.catalogue.secondaryNav.properties
                    )}
                </Link>

                <Link to={`${match.path}/files`}>
                    {this.context.translateService.getTranslation(
                        appTranslate.areas.catalogue.secondaryNav.files
                    )}
                </Link>

                <Link to={`${match.path}/product`}>
                    {this.context.translateService.getTranslation(
                        appTranslate.areas.catalogue.secondaryNav.products
                    )}
                </Link>
            </nav>
        );
    }
}
CatalogueSecondaryNav.contextType = AppContext;

export default CatalogueSecondaryNav;
