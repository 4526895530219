import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';
import Loader from '../../../../components/basic/Loader';
import { AppContext } from '../../../../services/context';
import axios from 'axios';

import AddModal from './AddModal';
import SearchModal from './SearchModal';
// import EditModal from './EditModal';
// import DeleteModal from './DeleteModal';

class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            categories: [],
            categoryId: null,
            products: [],
            modal: {
                add: {
                    open: false,
                    params: null
                },
                search: {
                    open: false,
                    params: null
                }
            },
            loaders: {
                category: false,
                product: false
            }
        };
    }

    componentDidMount() {
        this.getCategories();
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(
            prop,
            params,
            r,
            appTranslate.areas.catalogue.productNew.list
        );
    };

    loaderState = (loader, state) => {
        this.setState(prevState => {
            return {
                loaders: {
                    ...prevState.loaders,
                    [loader]: state
                }
            };
        });
    };

    setLoaderState = state => {
        this.setState({ loader: state });
    };

    getCategories = () => {
        this.loaderState('category', true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/catalogue/category/tree',
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        this.loaderState('category', false);
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState({ categories: response.data.data });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {});
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    onModalClose = (modal, response) => {
        this.modalState(modal, false, null);
        if (response) {
            // this.getTree();
        }

        if (modal === 'add') {
            this.getCategories();
        }
    };

    switchMainCategory = categoryId => {
        this.setState(prevState => {
            return {
                categories: prevState.categories.map(c => {
                    return {
                        ...c,
                        isOpen: c.categoryId === categoryId ? !c.isOpen : false
                    };
                })
            };
        });
    };

    selectCategory = categoryId => {
        this.setState({ categoryId: categoryId });
        this.getProducts(categoryId);
    };

    getProducts = categoryId => {
        this.loaderState('product', true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/catalogue/products/product/list',
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    params: {
                        propertyDefinitionId: '[1]',
                        categoryId: `[${categoryId}]`
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.loaderState('product', false);
                            console.log(response.data.data);

                            if (this.state.categoryId === categoryId) {
                                this.setState({
                                    products: response.data.data
                                });
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoaderState(false);
            });
    };

    render() {
        let loader = <div />;
        if (this.state.loaders.product || this.state.loaders.category) {
            loader = <Loader />;
        }

        let addModal = <div />;
        if (this.state.modal.add.open) {
            addModal = (
                <AddModal
                    visible={true}
                    close={response => this.onModalClose('add', response)}
                    params={this.state.modal.add.params}
                />
            );
        }

        let searchModal = <div />;
        if (this.state.modal.search.open) {
            searchModal = (
                <SearchModal
                    visible={true}
                    close={response => this.onModalClose('search', response)}
                    params={this.state.modal.search.params}
                />
            );
        }

        return (
            <div className='content-basic'>
                {loader}
                {addModal}
                {searchModal}
                <div className='content-basic__header'>
                    <button
                        className='_button-light'
                        onClick={() => this.modalState('add', true, { mainCategoryId: 0 })}
                    >
                        {this.T('addButton')}
                    </button>

                    <button className='_button-light' onClick={() => this.modalState('search', true, {})}>
                        {this.T('search')}
                    </button>
                </div>
                <div className='content-basic__content products-list-content'>
                    <div className='products-list'>
                        <div className='products-list__category-tree'>
                            {this.state.categories.map(c => (
                                <div className='products-list__category-tree__main-category-wrapper' key={c.categoryId}>
                                    <div
                                        className='products-list__category-tree__main-category'
                                        onClick={() => this.switchMainCategory(c.categoryId)}
                                    >
                                        <div className='products-list__category-tree__switch'>
                                            {c.isOpen ? 'expand_less' : 'expand_more'}
                                        </div>
                                        <div className='products-list__category-tree__label'>
                                            {this.T(c.label)} <span className='light'>{c.productCount}</span>
                                        </div>
                                    </div>
                                    {c.isOpen
                                        ? c.categories.map(cc => (
                                              <div
                                                  className={`products-list__category-tree__child-category ${
                                                      cc.categoryId === this.state.categoryId ? 'isSelected' : ''
                                                  }`}
                                                  onClick={() => {
                                                      this.selectCategory(cc.categoryId);
                                                  }}
                                                  key={cc.categoryId}
                                              >
                                                  <div className='products-list__category-tree__label'>
                                                      {this.T(cc.label)}{' '}
                                                      <span className='light'>{cc.productCount}</span>
                                                  </div>
                                                  <div className='products-list__category-tree__icon'>
                                                      {cc.categoryId === this.state.categoryId
                                                          ? 'check'
                                                          : 'keyboard_arrow_right'}
                                                  </div>
                                              </div>
                                          ))
                                        : null}
                                </div>
                            ))}
                        </div>
                        <div className='products-list__product-list'>
                            {this.state.products.map(p => (
                                <div className='products-list__product' key={p.productId}>
                                    <div className='products-list__product-id'>{p.productId}</div>
                                    <div className='products-list__product-name'>{this.T(p.properties[0].content)}</div>
                                    <div className='products-list__product-type'>{this.T(p.productType.label)}</div>
                                    <div className='products-list__product-number'>{p.ean}</div>
                                    <div className='products-list__product-visible'>
                                        {p.isVisible ? this.T('isVisible') : this.T('isNotVisible')}
                                    </div>
                                    <div className='products-list__product-buttons'>
                                        <Link
                                            to={`/catalogue/product/${p.productId}/details`}
                                            className='_button-light small'
                                        >
                                            {this.T('details')}
                                        </Link>
                                    </div>
                                </div>
                            ))}
                            {this.state.products.length === 0 && this.state.categoryId ? (
                                <div className='products-list__info'>{this.T('empty')}</div>
                            ) : !this.state.categoryId ? (
                                <div className='products-list__info'>{this.T('selectCategory')}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
List.contextType = AppContext;

export default List;
