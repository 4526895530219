import React, { Component } from 'react';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';
import { AppContext } from '../../../../services/context';
import axios, { post } from 'axios';

import Loader from '../../../../components/basic/Loader';

import Modal from '../../../../components/modal/Modal';

class ProductEditPhoto extends Component {
    constructor(props) {
        super(props);

        this.state = {
            file: null,
            loader: false
        };
    }

    T = (prop, params) => {
        let p;

        if (typeof prop === 'object') {
            p = prop;
        }
        if (typeof prop === 'string') {
            p = prop
                .split('.')
                .reduce(
                    (obj, a) => obj[a],
                    appTranslate.areas.catalogue.product.editPhoto
                );
        }

        return this.context.translateService.getTranslation(p, params);
    };

    loaderState = state => {
        this.setState({ loader: state });
    };

    onFileInput = file => {
        console.log(file);
        this.setState(prevState => {
            return {
                file: file
            };
        });
    };

    submit = () => {
        if (!this.state.file) {
            return;
        }
        let formData = new FormData();
        formData.append('image', this.state.file);
        formData.append('productId', this.props.parentState.product.productId);

        this.loaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return post(
                    appConfig.adminApiUrl +
                        '/catalogue/products/product/insertPhoto',
                    formData,
                    {
                        headers: {
                            ...authHeader,
                            'content-type': 'multipart/form-data'
                        }
                    }
                )
                    .then(response => {
                        console.log(response);
                        // this.close(true);
                        this.loaderState(false);
                        this.props.refresh();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    onDelete = photoId => {
        this.loaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        appConfig.adminApiUrl +
                        '/catalogue/products/product/deletePhoto',
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        productId: this.props.parentState.product.productId,
                        photoId: photoId
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.loaderState(false);
                        this.props.refresh();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    onSetMain = photoId => {
        this.loaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        appConfig.adminApiUrl +
                        '/catalogue/products/product/setMainPhoto',
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        productId: this.props.parentState.product.productId,
                        photoId: photoId
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.loaderState(false);
                        this.props.refresh();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    render() {
        let state = this.props.parentState;

        let loader = <div />;

        if (this.state.loader || this.props.parentState.loader) {
            loader = <Loader />;
        }

        const footer = (
            <div className='space-between'>
                <button
                    className='_button-light'
                    onClick={() => this.props.close()}
                >
                    {this.T('ready')}
                </button>
            </div>
        );

        let photos = (
            <div className=''>
                <h3>{this.T('noPhotos')}</h3>
            </div>
        );

        if (state.product.photos.length > 0) {
            photos = state.product.photos.map(photo => {
                return (
                    <div
                        className='product-edit-photo__photo-wrapper'
                        key={photo.photoId}
                    >
                        <div className='product-edit-photo__photo-image'>
                            <img src={appConfig.photoUrl + photo.filename} alt='' />
                        </div>
                        <div className='product-edit-photo__photo-is-main'>
                            {photo.isMain ? this.T('isMain') : ''}
                        </div>
                        <div className='product-edit-photo__photo-buttons'>
                            <div
                                className='_button-light small'
                                onClick={() => this.onSetMain(photo.photoId)}
                            >
                                {this.T('setMain')}
                            </div>
                            <div
                                className='_button-light small'
                                onClick={() => this.onDelete(photo.photoId)}
                            >
                                {this.T('delete')}
                            </div>
                        </div>
                    </div>
                );
            });
        }

        const content = (
            <div className='product-edit-photo__content'>
                {loader}
                <div className='product-edit-photo__upload-photo'>
                    <div className='product-edit-photo__input-wrapper'>
                        <div className='product-edit-photo__input-placeholder'>
                            <i className='material-icons'>cloud_upload</i>
                            <span className='filename'>
                                {this.state.file
                                    ? this.state.file.name
                                    : this.T('selectPhoto')}
                            </span>
                        </div>
                        <input
                            type='file'
                            className='product-edit-photo__input'
                            onChange={event =>
                                this.onFileInput(event.target.files[0])
                            }
                        />
                    </div>

                    <button
                        className='product-edit-photo__button'
                        onClick={() => this.submit()}
                    >
                        {this.T('addPhoto')}
                    </button>
                </div>
                {photos}
            </div>
        );

        return (
            <Modal
                title={this.T('title')}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}
ProductEditPhoto.contextType = AppContext;

export default ProductEditPhoto;
