import BasicFormComponent from '../../../../components/basicFormComponent/BasicFormComponent';
import React from 'react';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';
import axios from 'axios';

import { emptyObject } from '../../../../services/translateService';

import TextField from '../../../../components/form/TextField';
import CheckBox from '../../../../components/form/CheckBox';

import { checkNotEmptyText } from '../../../../helpers/validator/Validator';

import Modal from '../../../../components/modal/Modal';
import Loader from '../../../../components/basic/Loader';

class AddModal extends BasicFormComponent {
    TObject = appTranslate.areas.company.destination.addModal;

    constructor(props) {
        super(props);

        this.state = {
            form: {
                label: { value: '', error: '', checks: [checkNotEmptyText] },
                country: { value: '' },
                postalCode: { value: '' },
                region: { value: '' },
                city: { value: '' },
                street: { value: '' },
                houseNumber: { value: '' },
                extraInfo: { value: '' }
            },
            loader: false
        };
    }

    prepareData = () => {
        return {
            companyId: this.props.params.id,
            label: this.state.form.label.value,
            country: this.state.form.country.value,
            postalCode: this.state.form.postalCode.value,
            region: this.state.form.region.value,
            city: this.state.form.city.value,
            street: this.state.form.street.value,
            houseNumber: this.state.form.houseNumber.value,
            extraInfo: this.state.form.extraInfo.value
        };
    };

    onSubmit = () => {
        console.log(this.validateFields());
        if (this.validateFields()) {
            this.submit();
        }
    };

    submit = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/company/destination/insert',
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        destination: this.prepareData()
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    render() {
        const footer = (
            <div className='space-between'>
                <button className='_button-light' onClick={() => this.onSubmit()}>
                    {this.T('save')}
                </button>

                <button className='_button-light' onClick={() => this.props.close(false)}>
                    {this.T('cancel')}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader) {
            loader = <Loader />;
        }

        const content = (
            <div>
                {loader}
                <TextField
                    field={this.state.form.label}
                    onInput={value => this.onInputValue('label', value)}
                    onBlur={() => this.validateField('label')}
                    label={this.T('label')}
                />

                <TextField
                    field={this.state.form.country}
                    onInput={value => this.onInputValue('country', value)}
                    onBlur={() => this.validateField('country')}
                    label={this.T('country')}
                />

                <TextField
                    field={this.state.form.postalCode}
                    onInput={value => this.onInputValue('postalCode', value)}
                    onBlur={() => this.validateField('postalCode')}
                    label={this.T('postalCode')}
                />

                <TextField
                    field={this.state.form.region}
                    onInput={value => this.onInputValue('region', value)}
                    onBlur={() => this.validateField('region')}
                    label={this.T('region')}
                />

                <TextField
                    field={this.state.form.city}
                    onInput={value => this.onInputValue('city', value)}
                    onBlur={() => this.validateField('city')}
                    label={this.T('city')}
                />

                <TextField
                    field={this.state.form.street}
                    onInput={value => this.onInputValue('street', value)}
                    onBlur={() => this.validateField('street')}
                    label={this.T('street')}
                />

                <TextField
                    field={this.state.form.houseNumber}
                    onInput={value => this.onInputValue('houseNumber', value)}
                    onBlur={() => this.validateField('houseNumber')}
                    label={this.T('houseNumber')}
                />

                <TextField
                    field={this.state.form.extraInfo}
                    onInput={value => this.onInputValue('extraInfo', value)}
                    onBlur={() => this.validateField('extraInfo')}
                    label={this.T('extraInfo')}
                />
            </div>
        );
        return (
            <Modal
                title={this.T('title')}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default AddModal;
