import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';
import Loader from '../../../../components/basic/Loader';
import { AppContext } from '../../../../services/context';
import axios from 'axios';

import EditModal from './ContractEditModal';
import DeleteModal from './ContractDeleteModal';

import ProductCount from './ContractProductQuantityModal';

class Details extends Component {
    constructor(props) {
        super(props);

        this.contractId = this.props.match.params.contractId;

        this.state = {
            loader: false,
            contract: null,
            productTree: [],
            openTree: {
                main: null,
                secondary: null
            },
            loaders: {
                productTree: false
            },
            modal: {
                edit: {
                    open: false,
                    params: null
                },
                delete: {
                    open: false,
                    params: null
                },
                productCount: {
                    open: false,
                    params: null
                }
            }
        };
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(
            prop,
            params,
            r,
            appTranslate.areas.contracts.contract.details
        );
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    onModalClose = (modal, response) => {
        this.modalState(modal, false, null);

        if (modal === 'delete') {
            if (response) {
                window.location = '/contracts/contract';
            }
            return;
        }

        if (response) {
            this.getData();
        }
    };

    componentDidMount() {
        this.getData();
    }

    setLoaderState = state => {
        this.setState({ loader: state });
    };

    setLoader = (loader, state) => {
        this.setState(prev => {
            return {
                loaders: {
                    ...prev.loaders,
                    [loader]: state
                }
            };
        });
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    openTree = (level, id) => {
        this.setState(prev => {
            return {
                openTree: {
                    ...prev.openTree,
                    [level]: prev.openTree[level] === id ? null : id
                }
            };
        });
    };

    getData = () => {
        console.log(this.props.match.params.fileId);
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/contract/contract/details/' + this.props.match.params.contractId,
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState({ contract: response.data.data });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    render() {
        let loader = <div />;
        if (this.state.loader) {
            loader = <Loader />;
        }

        if (!this.state.contract) {
            return <div className='content-basic'>{loader}</div>;
        }

        let editModal = <div />;
        if (this.state.modal.edit.open) {
            editModal = (
                <EditModal
                    close={response => this.onModalClose('edit', response)}
                    params={this.state.modal.edit.params}
                />
            );
        }

        let deleteModal = <div />;
        if (this.state.modal.delete.open) {
            deleteModal = (
                <DeleteModal
                    close={response => this.onModalClose('delete', response)}
                    params={this.state.modal.delete.params}
                />
            );
        }

        let productCount = <div />;
        if (this.state.modal.productCount.open) {
            deleteModal = (
                <ProductCount
                    close={response => this.onModalClose('productCount', response)}
                    params={this.state.modal.productCount.params}
                />
            );
        }

        return (
            <div className='content-basic'>
                {loader}
                {editModal}
                {deleteModal}
                {productCount}
                <div className='content-basic__header'>
                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('edit', true, {
                                id: this.contractId
                            })
                        }
                    >
                        {this.T('editCompany')}
                    </button>

                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('delete', true, {
                                id: this.contractId
                            })
                        }
                    >
                        {this.T('deleteCompany')}
                    </button>

                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('productCount', true, {
                                id: this.contractId
                            })
                        }
                    >
                        {this.T('addDestination')}
                    </button>
                </div>
                <div className='content-basic__header'>
                    <div className='product-details'>
                        <div className='product-details__row'>
                            <div className='product-details__name'>
                                <div className='product-details__name-main'>{this.state.contract.label}</div>
                                <div className='product-details__name-second'>{this.T('label')}</div>
                            </div>
                        </div>
                        <div className='product-details__row'>
                            <div className='product-details__name'>
                                <div className='product-details__name-main'>
                                    {moment(this.state.contract.contractStart).format('YYYY-MM-DD')}
                                </div>
                                <div className='product-details__name-second'>{this.T('contractStart')}</div>
                            </div>
                        </div>
                        {this.state.contract.isTimeLimited ? (
                            <div className='product-details__row'>
                                <div className='product-details__name'>
                                    <div className='product-details__name-main'>
                                        {moment(this.state.contract.contractEnd).format('YYYY-MM-DD')}
                                    </div>
                                    <div className='product-details__name-second'>{this.T('contractEnd')}</div>
                                </div>
                            </div>
                        ) : null}
                        <div className='product-details__row'>
                            <div className='product-details__name'>
                                <div className='product-details__name-main'>
                                    {this.state.contract.company.companyName} ({this.state.contract.company.nip})
                                </div>
                                <div className='product-details__name-second'>{this.T('company')}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='content-basic__header'>
                    <div className='product-details'>
                        <div className='product-details__row'>
                            <div className='product-details__property-group'>{this.T('products')}</div>
                        </div>
                        <div className='product-details__row'>
                            <table>
                                <thead>
                                    <tr>
                                        <td>LP</td>
                                        <td>Nazwa produktu</td>
                                        <td>Kategoria</td>
                                        <td>Kontrakt</td>
                                        <td>Zamówiono</td>
                                        <td>Zostało</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.contract.products.map((p, i) => (
                                        <tr key={p.productId}>
                                            <td>{i + 1}</td>
                                            <td>{this.T(p.productName)}</td>
                                            <td>
                                                {this.T(p.mainCategory.label)} > {this.T(p.category.label)}
                                            </td>
                                            <td>{p.quantity.contract}</td>
                                            <td>{p.quantity.used}</td>
                                            <td>{p.quantity.left}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Details.contextType = AppContext;

export default Details;
