import React, { Component } from 'react';
import './App.scss';

import { BrowserRouter as Router} from 'react-router-dom';

import { AppContext } from './services/context';
import { AuthService } from './services/authService';

import Login from './views/Login';

import Main from './views/Main';
import { TranslateService } from './services/translateService';

class App extends Component {
    constructor(props) {
        super(props);

        this.appContext = {
            authService: new AuthService(this),
            translateService: new TranslateService(this)
        };

        this.state = {
            loader: true,
            session: this.appContext.authService.loadSession(),
            language: this.appContext.translateService.getLanguage()
        };
    }

    render() {
        if (this.appContext.authService.isSessionActive()) {
            return (
                <AppContext.Provider value={this.appContext}>
                    <Router>
                        <Main />
                    </Router>
                </AppContext.Provider>
            );
        } else {
            return (
                <AppContext.Provider value={this.appContext}>
                    <Router>
                        <Login />
                    </Router>
                </AppContext.Provider>
            );
        }
    }
}

export default App;
