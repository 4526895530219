import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';
import Loader from '../../../../components/basic/Loader';
import { AppContext } from '../../../../services/context';
import axios from 'axios';

import GroupAddModal from './GroupAddModal';
import GroupEditModal from './GroupEditModal';
import GroupDeleteModal from './GroupDeleteModal';

import ChildAddModal from './ChildAddModal';
import ChildEditModal from './ChildEditModal';
import ChildDeleteModal from './ChildDeleteModal';
// import EditModal from './EditModal';
// import DeleteModal from './DeleteModal';

class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            tree: [],
            modal: {
                addGroup: {
                    open: false,
                    params: null
                },
                editGroup: {
                    open: false,
                    params: null
                },
                deleteGroup: {
                    open: false,
                    params: null
                },
                addChild: {
                    open: false,
                    params: null
                },
                editChild: {
                    open: false,
                    params: null
                },
                deleteChild: {
                    open: false,
                    params: null
                }
            },
            treeOpen: null
        };
    }

    componentDidMount() {
        this.getTree();
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(prop, params, r, appTranslate.areas.catalogue.files.list);
    };

    setLoaderState = state => {
        this.setState({ loader: state });
    };

    getTree = () => {
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/catalogue/files/file/tree',
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState({ tree: response.data.data });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    onModalClose = (modal, response) => {
        this.modalState(modal, false, null);
        if (response) {
            this.getTree();
        }
    };

    switchMainCategory = id => {
        // this.setState(prevState => {
        //     return {
        //         tree: prevState.tree.map(e => {
        //             return {
        //                 ...e,
        //                 isOpen: e.productPropertyGroupId === id ? !e.isOpen : false
        //             };
        //         })
        //     };
        // });

        this.setState(prev => {
            return {
                treeOpen: prev.treeOpen === id ? null : id
            };
        });
    };

    render() {
        let loader = <div />;
        if (this.state.loader) {
            loader = <Loader />;
        }

        let addGroupModal = <div />;
        if (this.state.modal.addGroup.open) {
            addGroupModal = (
                <GroupAddModal
                    close={response => this.onModalClose('addGroup', response)}
                    params={this.state.modal.addGroup.params}
                />
            );
        }

        let editGroupModal = <div />;
        if (this.state.modal.editGroup.open) {
            editGroupModal = (
                <GroupEditModal
                    close={response => this.onModalClose('editGroup', response)}
                    params={this.state.modal.editGroup.params}
                />
            );
        }

        let deleteGroupModal = <div />;
        if (this.state.modal.deleteGroup.open) {
            deleteGroupModal = (
                <GroupDeleteModal
                    close={response => this.onModalClose('deleteGroup', response)}
                    params={this.state.modal.deleteGroup.params}
                />
            );
        }

        let addPropertyModal = <div />;
        if (this.state.modal.addChild.open) {
            addPropertyModal = (
                <ChildAddModal
                    close={response => this.onModalClose('addChild', response)}
                    params={this.state.modal.addChild.params}
                />
            );
        }

        let editPropertyModal = <div />;
        if (this.state.modal.editChild.open) {
            editPropertyModal = (
                <ChildEditModal
                    close={response => this.onModalClose('editChild', response)}
                    params={this.state.modal.editChild.params}
                />
            );
        }

        let deletePropertyModal = <div />;
        if (this.state.modal.deleteChild.open) {
            deletePropertyModal = (
                <ChildDeleteModal
                    close={response => this.onModalClose('deleteChild', response)}
                    params={this.state.modal.deleteChild.params}
                />
            );
        }

        return (
            <div className='content-basic'>
                {loader}
                {addGroupModal}
                {editGroupModal}
                {deleteGroupModal}
                {addPropertyModal}
                {editPropertyModal}
                {deletePropertyModal}
                <div className='content-basic__header'>
                    <button className='_button-light' onClick={() => this.modalState('addGroup', true, null)}>
                        {this.T('addButton')}
                    </button>
                </div>
                <div className='content-basic__content'>
                    <div className='category-tree'>
                        {this.state.tree.reduce((p, e) => {
                            p.push(
                                <div className='category-tree__wrapper' key={e.fileGroupId}>
                                    <div className='category-tree__main-category'>
                                        <div
                                            className='category-tree__main-category__switch'
                                            onClick={() => {
                                                this.switchMainCategory(e.fileGroupId);
                                            }}
                                        >
                                            {e.fileGroupId === this.state.treeOpen ? 'expand_less' : 'expand_more'}
                                        </div>
                                        <div
                                            className='category-tree__main-category__label'
                                            onClick={() => {
                                                this.switchMainCategory(e.fileGroupId);
                                            }}
                                        >
                                            {this.T(e.label)}
                                        </div>

                                        <div className='category-tree__main-category__count'>
                                            {this.T('table.categoryCount')}: {e.files.length}
                                        </div>

                                        <div className='category-tree__main-category__buttons'>
                                            <button
                                                className='_button-light small'
                                                onClick={() =>
                                                    this.modalState('addChild', true, {
                                                        id: e.fileGroupId
                                                    })
                                                }
                                            >
                                                {this.T('table.addSubcategory')}
                                            </button>
                                            <button
                                                className='_button-light small'
                                                onClick={() =>
                                                    this.modalState('editGroup', true, {
                                                        id: e.fileGroupId
                                                    })
                                                }
                                            >
                                                {this.T('table.edit')}
                                            </button>
                                            <button
                                                className={`_button-light small ${
                                                    e.files.length > 0 ? 'disabled' : ''
                                                }`}
                                                onClick={() => {
                                                    if (e.files.length > 0) {
                                                        return;
                                                    }
                                                    this.modalState('deleteGroup', true, {
                                                        id: e.fileGroupId
                                                    });
                                                }}
                                                title={e.files.length > 0 ? this.T('table.deleteDisabledReason') : ''}
                                            >
                                                {this.T('table.delete')}
                                            </button>
                                        </div>
                                    </div>
                                    {e.fileGroupId === this.state.treeOpen
                                        ? e.files.map(pp => {
                                              return (
                                                  <div className='category-tree__child-category' key={pp.fileId}>
                                                      <div className='category-tree__child-category__label'>
                                                          {this.T(pp.label)}
                                                      </div>

                                                      <div className='category-tree__child-category__buttons'>
                                                          <Link
                                                              to={`/catalogue/files/${pp.fileId}/details`}
                                                              className='_button-light small'
                                                          >
                                                              {this.T('table.details')}
                                                          </Link>
                                                          <button
                                                              className='_button-light small'
                                                              onClick={() =>
                                                                  this.modalState('deleteChild', true, {
                                                                      id: pp.fileId
                                                                  })
                                                              }
                                                          >
                                                              {this.T('table.delete')}
                                                          </button>
                                                      </div>
                                                  </div>
                                              );
                                          })
                                        : null}
                                </div>
                            );
                            return p;
                        }, [])}
                    </div>
                </div>
            </div>
        );
    }
}
List.contextType = AppContext;

export default List;
