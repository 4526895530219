import BasicFormComponent from '../../../../components/basicFormComponent/BasicFormComponent';
import React from 'react';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';
import axios from 'axios';

import Modal from '../../../../components/modal/Modal';
import Loader from '../../../../components/basic/Loader';

class CopyBindingModal extends BasicFormComponent {
    TObject = appTranslate.areas.catalogue.productNew.copyBindingModal;

    currentProductTypeId = -1;

    constructor(props) {
        super(props);

        this.state = {
            tree: [],
            treeOpen: {
                main: null,
                secondary: null
            },
            loader: 0
        };
    }

    setLoader = value => {
        this.setState(prevState => {
            let l = prevState.loader + (value ? 1 : -1);
            if (l < 0) {
                l = 0;
            }
            return {
                loader: l
            };
        });
    };

    componentDidMount() {
        this.fetchTree();
    }

    fetchTree = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        appConfig.adminApiUrl +
                        '/catalogue/products/binding/treeAll/' +
                        this.props.params.productId +
                        (this.props.params.reverse ? '?reverse=true' : ''),
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            this.setLoader(false);
                            this.setState({
                                tree: response.data.data.map(c => {
                                    return {
                                        ...c,
                                        categories: c.categories.map(cc => {
                                            return {
                                                ...cc,
                                                products: cc.products.map(p => {
                                                    if (p.productId === this.props.params.productId) {
                                                        this.currentProductTypeId = p.productType.productTypeId;
                                                    }
                                                    return p;
                                                })
                                            };
                                        })
                                    };
                                })
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    prepareData = () => {};

    onSubmit = () => {
        this.submit();
    };

    submit = productIdCopy => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/catalogue/products/binding/copy',
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        params: {
                            productId: parseInt(this.props.params.productId),
                            productIdCopy: parseInt(productIdCopy)
                        }
                    }
                })
                    .then(response => {
                        // this.setLoader(false);
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    treeOpen = (level, id) => {
        this.setState(prev => {
            return {
                treeOpen: {
                    ...prev.treeOpen,
                    [level]: prev.treeOpen[level] === id ? null : id
                }
            };
        });
    };

    render() {
        const footer = (
            <div className='space-between'>
                <button className='_button-light' onClick={() => this.props.close(false)}>
                    {this.T('cancel')}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader > 0) {
            loader = <Loader />;
        }

        const content = (
            <div className='binding-modal__container'>
                {loader}
                {this.state.tree.map(c => {
                    let isOpen = c.categoryId === this.state.treeOpen.main;
                    return (
                        <div className='binding-modal__main-category-wrapper' key={c.categoryId}>
                            <div
                                className='binding-modal__main-category'
                                onClick={() => this.treeOpen('main', c.categoryId)}
                            >
                                <div className='binding-modal__main-category-icon'>
                                    {isOpen ? 'expand_less' : 'expand_more'}
                                </div>
                                <div className='binding-modal__main-category-label'>
                                    {this.T(c.label)}
                                    <span className='binding-modal__main-category-product-count'>{c.productCount}</span>
                                </div>
                            </div>
                            {isOpen
                                ? c.categories.map(cc => {
                                      let isOpenSecondary = cc.categoryId === this.state.treeOpen.secondary;

                                      return (
                                          <div className='binding-modal__child-category-wrapper' key={cc.categoryId}>
                                              <div className='binding-modal__child-category'>
                                                  <div
                                                      className='binding-modal__child-category-icon'
                                                      onClick={() => this.treeOpen('secondary', cc.categoryId)}
                                                  >
                                                      {isOpenSecondary ? 'expand_less' : 'expand_more'}
                                                  </div>
                                                  <div
                                                      className='binding-modal__child-category-label'
                                                      onClick={() => this.treeOpen('secondary', cc.categoryId)}
                                                  >
                                                      {this.T(cc.label)}
                                                      <span className='binding-modal__child-category-product-count'>
                                                          {cc.productCount}
                                                      </span>
                                                  </div>
                                              </div>
                                              {isOpenSecondary
                                                  ? cc.products.map(p => {
                                                        return (
                                                            <div className='binding-modal__product' key={p.productId}>
                                                                <div className='binding-modal__product-label'>
                                                                    {this.T(p.productName)}
                                                                </div>
                                                                <div className='binding-modal__product-number'>
                                                                    {p.ean}
                                                                </div>
                                                                <div className='binding-modal__product-binding'>
                                                                    {p.productType.productTypeId ===
                                                                    this.currentProductTypeId ? (
                                                                        <div
                                                                            className='_button-light small'
                                                                            onClick={() => this.submit(p.productId)}
                                                                        >
                                                                            {this.T('copy')}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                  : null}
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                    );
                })}
            </div>
        );
        return (
            <Modal
                title={this.T('title')}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default CopyBindingModal;
