import BasicFormComponent from '../../../../components/basicFormComponent/BasicFormComponent';
import React from 'react';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';
import axios from 'axios';

import { emptyObject } from '../../../../services/translateService';

import TextFieldL from '../../../../components/form/TextFieldL';
import CheckBox from '../../../../components/form/CheckBox';
import SelectField from '../../../../components/form/SelectField';

import { checkNotEmptyTextL } from '../../../../helpers/validator/Validator';

import Modal from '../../../../components/modal/Modal';
import Loader from '../../../../components/basic/Loader';

class FileBindingModal extends BasicFormComponent {
    TObject = appTranslate.areas.catalogue.files.bindingModal;

    constructor(props) {
        super(props);

        this.state = {
            tree: [],
            main: null,
            secondary: null,
            loader: true
        };
    }
    openMain = id => {
        this.setState(prev => {
            return {
                main: prev.main === id ? null : id
            };
        });
    };

    openSecondary = id => {
        this.setState(prev => {
            return {
                secondary: prev.secondary === id ? null : id
            };
        });
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/catalogue/files/fileProduct/fileTreeAll/' + this.props.params.id,
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            this.setLoader(false);
                            this.setState(prevState => {
                                return {
                                    tree: response.data.data
                                };
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    prepareData = () => {
        const products = [];
        this.state.tree.forEach(c => {
            c.categories.forEach(cc => {
                cc.products.forEach(p => {
                    console.log(p.isFileBinded);
                    if (p.isFileBinded === true || p.isFileBinded === 'true') {
                        products.push(p.productId);
                    }
                });
            });
        });

        return {
            fileId: this.props.params.id,
            products: products
        };
    };

    onSubmit = () => {
        console.log(this.prepareData());
        // console.log(this.prepareData());
        // if (this.validateFields()) {
        //     this.submit();
        // }
        this.submit();
    };

    submit = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/catalogue/files/fileProduct/updateByFile',
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        ...this.prepareData()
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    changeBindingType = (mainCategoryId, childCategoryId, productId, value) => {
        value = value === 'true';
        this.setState(prevState => {
            return {
                tree: prevState.tree.map(c => {
                    if (c.categoryId !== mainCategoryId) {
                        return c;
                    }
                    return {
                        ...c,
                        categories: c.categories.map(cc => {
                            if (cc.categoryId !== childCategoryId) {
                                return cc;
                            }
                            return {
                                ...cc,
                                products: cc.products.map(p => {
                                    if (p.productId !== productId) {
                                        return p;
                                    }
                                    return {
                                        ...p,
                                        isFileBinded: value
                                    };
                                })
                            };
                        })
                    };
                })
            };
        });
    };

    changeSubcategoryBindingType = (mainCategoryId, childCategoryId, value) => {
        value = value === 'true';
        this.setState(prevState => {
            return {
                tree: prevState.tree.map(c => {
                    if (c.categoryId !== mainCategoryId) {
                        return c;
                    }
                    return {
                        ...c,
                        categories: c.categories.map(cc => {
                            if (cc.categoryId !== childCategoryId) {
                                return cc;
                            }
                            return {
                                ...cc,
                                products: cc.products.map(p => {
                                    return {
                                        ...p,
                                        isFileBinded: value
                                    };
                                })
                            };
                        })
                    };
                })
            };
        });
    };

    render() {
        const footer = (
            <div className='space-between'>
                <button className='_button-light' onClick={() => this.onSubmit()}>
                    {this.T('save')}
                </button>

                <button className='_button-light' onClick={() => this.props.close(false)}>
                    {this.T('cancel')}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader) {
            loader = <Loader />;
        }

        const content = (
            <div>
                {loader}
                <div className='binding-modal__container'>
                    {this.state.tree.map(c => {
                        const categories =
                            c.categoryId === this.state.main
                                ? c.categories.map(cc => {
                                      let cc_sameValue = true;
                                      let cc_value = null;

                                      const products =
                                          cc.categoryId === this.state.secondary
                                              ? cc.products.map(p => {
                                                    if (cc_value === null) {
                                                        cc_value = p.isFileBinded;
                                                    } else {
                                                        if (cc_sameValue && cc_value !== p.isFileBinded) {
                                                            cc_sameValue = false;
                                                            cc_value = -1;
                                                        }
                                                    }
                                                    return (
                                                        <div className='binding-modal__product' key={p.productId}>
                                                            <div className='binding-modal__product-label'>
                                                                {this.T(p.productName)}
                                                            </div>
                                                            <div className='binding-modal__product-number'>{p.ean}</div>
                                                            <div className='binding-modal__product-binding'>
                                                                <select
                                                                    value={p.isFileBinded}
                                                                    onChange={event =>
                                                                        this.changeBindingType(
                                                                            c.categoryId,
                                                                            cc.categoryId,
                                                                            p.productId,
                                                                            event.target.value
                                                                        )
                                                                    }
                                                                >
                                                                    <option value={true}>{this.T('binded')}</option>
                                                                    <option value={false}>{this.T('notBinded')}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                              : null;

                                      return (
                                          <div className='binding-modal__child-category-wrapper' key={cc.categoryId}>
                                              <div className='binding-modal__child-category'>
                                                  <div
                                                      className='binding-modal__child-category-icon'
                                                      onClick={() => this.openSecondary(cc.categoryId)}
                                                  >
                                                      {cc.categoryId === this.state.secondary
                                                          ? 'expand_less'
                                                          : 'expand_more'}
                                                  </div>
                                                  <div
                                                      className='binding-modal__child-category-label'
                                                      onClick={() => this.openSecondary(cc.categoryId)}
                                                  >
                                                      {this.T(cc.label)}
                                                      <span className='binding-modal__child-category-product-count'>
                                                          {cc.productCount}
                                                      </span>
                                                  </div>
                                                  {cc.categoryId === this.state.secondary ? (
                                                      <div className='binding-modal__child-category__change-binding'>
                                                          <select
                                                              value={cc_value}
                                                              onChange={event =>
                                                                  this.changeSubcategoryBindingType(
                                                                      c.categoryId,
                                                                      cc.categoryId,
                                                                      event.target.value
                                                                  )
                                                              }
                                                          >
                                                              <option value={-1} disabled={true}>
                                                                  {this.T('manyBindings')}
                                                              </option>
                                                              <option value={true}>{this.T('binded')}</option>
                                                              <option value={false}>{this.T('notBinded')}</option>
                                                          </select>
                                                      </div>
                                                  ) : null}
                                              </div>
                                              {products}
                                          </div>
                                      );
                                  })
                                : null;
                        return (
                            <div className='binding-modal__main-category-wrapper' key={c.categoryId}>
                                <div
                                    className='binding-modal__main-category'
                                    onClick={() => this.openMain(c.categoryId)}
                                >
                                    <div className='binding-modal__main-category-icon'>
                                        {c.categoryId === this.state.main ? 'expand_less' : 'expand_more'}
                                    </div>
                                    <div className='binding-modal__main-category-label'>
                                        {this.T(c.label)}
                                        <span className='binding-modal__main-category-product-count'>
                                            {c.productCount}
                                        </span>
                                    </div>
                                </div>
                                {categories}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
        return (
            <Modal
                title={this.T('title')}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default FileBindingModal;
