import BasicFormComponent from '../../../../components/basicFormComponent/BasicFormComponent';
import React from 'react';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';
import axios from 'axios';

import Modal from '../../../../components/modal/Modal';
import Loader from '../../../../components/basic/Loader';

class FileBindingModal extends BasicFormComponent {
    TObject = appTranslate.areas.catalogue.productNew.bindingModal;

    constructor(props) {
        super(props);

        this.state = {
            tree: [],
            loader: 0,
            treeOpen: {
                main: null
            }
        };
    }

    setLoader = value => {
        this.setState(prevState => {
            let l = prevState.loader + (value ? 1 : -1);
            if (l < 0) {
                l = 0;
            }
            return {
                loader: l
            };
        });
    };

    componentDidMount() {
        this.fetchTree();
    }

    fetchTree = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        appConfig.adminApiUrl +
                        '/catalogue/files/fileProduct/fileTreeAllByProduct/' +
                        this.props.params.id,
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            this.setLoader(false);
                            this.setState({
                                tree: response.data.data
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    prepareData = () => {
        let files = [];
        this.state.tree.forEach(g => {
            g.files.forEach(f => {
                if (f.isBinded === 'true' || f.isBinded === true) {
                    files.push(f.fileId);
                }
            });
        });
        return {
            productId: this.props.params.id,
            files: files
        };
    };

    onSubmit = () => {
        this.submit();
    };

    submit = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/catalogue/files/fileProduct/updateByProduct',
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        ...this.prepareData()
                    }
                })
                    .then(response => {
                        // this.setLoader(false);
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    openTree = (level, id) => {
        this.setState(prev => {
            return {
                treeOpen: {
                    ...prev.treeOpen,
                    [level]: prev.treeOpen[level] === id ? null : id
                }
            };
        });
    };

    changeBinding = (fileGroupId, fileId, value) => {
        this.setState(prev => {
            return {
                tree: prev.tree.map(g => {
                    if (g.fileGroupId !== fileGroupId) {
                        return g;
                    }
                    return {
                        ...g,
                        files: g.files.map(f => {
                            if (f.fileId !== fileId) {
                                return f;
                            }
                            return {
                                ...f,
                                isBinded: value
                            };
                        })
                    };
                })
            };
        });
    };

    render() {
        const footer = (
            <div className='space-between'>
                <button className='_button-light' onClick={() => this.onSubmit()}>
                    {this.T('save')}
                </button>

                <button className='_button-light' onClick={() => this.props.close(false)}>
                    {this.T('cancel')}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader > 0) {
            loader = <Loader />;
        }

        const content = (
            <div className='binding-modal__container'>
                {loader}
                {this.state.tree.map(g => {
                    return (
                        <div className='binding-modal__main-category-wrapper' key={g.fileGroupId}>
                            <div
                                className='binding-modal__main-category'
                                onClick={() => this.openTree('main', g.fileGroupId)}
                            >
                                <div className='binding-modal__main-category-icon'>
                                    {g.fileGroupId === this.state.treeOpen.main ? 'expand_less' : 'expand_more'}
                                </div>
                                <div className='binding-modal__main-category-label'>
                                    {this.T(g.label)}
                                    <span className='binding-modal__main-category-product-count'>{g.files.length}</span>
                                </div>
                            </div>
                            {g.fileGroupId === this.state.treeOpen.main
                                ? g.files.map(f => {
                                      return (
                                          <div className='binding-modal__product' key={f.fileId}>
                                              <div className='binding-modal__product-label'>{this.T(f.label)}</div>
                                              <div className='binding-modal__product-binding'>
                                                  <select
                                                      value={f.isBinded}
                                                      onChange={event =>
                                                          this.changeBinding(
                                                              g.fileGroupId,
                                                              f.fileId,
                                                              event.target.value
                                                          )
                                                      }
                                                  >
                                                      <option value={true}>tak</option>
                                                      <option value={false}>nie</option>
                                                  </select>
                                              </div>
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                    );
                })}
            </div>
        );
        return (
            <Modal
                title={this.T('title')}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default FileBindingModal;
