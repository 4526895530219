import BasicFormComponent from '../../../../components/basicFormComponent/BasicFormComponent';
import React from 'react';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';
import axios from 'axios';
import moment from 'moment';

import TextField from '../../../../components/form/TextField';
import CheckBox from '../../../../components/form/CheckBox';

import { checkNotEmptyText, checkValidDate } from '../../../../helpers/validator/Validator';

import Modal from '../../../../components/modal/Modal';
import Loader from '../../../../components/basic/Loader';

class EditModal extends BasicFormComponent {
    TObject = appTranslate.areas.contracts.contract.editModal;

    constructor(props) {
        super(props);

        this.state = {
            companies: [],
            form: {
                companyId: {
                    value: undefined
                },
                label: { value: '', error: '', checks: [checkNotEmptyText] },
                contractStart: { value: moment().format('YYYY-MM-DD'), error: '', checks: [checkValidDate] },
                isTimeLimited: { value: false },
                contractEnd: {
                    value: moment().format('YYYY-MM-DD'),
                    error: '',
                    checks: [
                        field => {
                            return this.state.form.isTimeLimited.value ? checkValidDate(field) : [];
                        }
                    ]
                }
            },
            loader: true
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/contract/contract/detailsForEdit/' + this.props.params.id,
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            const data = response.data.data;
                            this.setLoader(false);
                            this.setState(prevState => {
                                return {
                                    form: {
                                        ...prevState.form,
                                        label: {
                                            ...prevState.form.label,
                                            value: data.label
                                        },
                                        contractStart: {
                                            ...prevState.form.contractStart,
                                            value: moment(data.contractStart).format('YYYY-MM-DD')
                                        },
                                        contractEnd: {
                                            ...prevState.form.contractEnd,
                                            value: data.contractEnd
                                                ? moment(data.contractEnd).format('YYYY-MM-DD')
                                                : ''
                                        },
                                        isTimeLimited: {
                                            ...prevState.form.isTimeLimited,
                                            value: data.isTimeLimited
                                        }
                                    }
                                };
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    prepareData = () => {
        return {
            contractId: this.props.params.id,
            label: this.state.form.label.value,
            isTimeLimited: this.state.form.isTimeLimited.value,
            contractStart: this.state.form.contractStart.value,
            contractEnd: this.state.form.contractEnd.value,
            isTerminated: false
        };
    };

    onSubmit = () => {
        console.log(this.prepareData());
        if (this.validateFields()) {
            this.submit();
        }
    };

    submit = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/contract/contract/update',
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        contract: this.prepareData()
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    render() {
        const footer = (
            <div className='space-between'>
                <button className='_button-light' onClick={() => this.onSubmit()}>
                    {this.T('save')}
                </button>

                <button className='_button-light' onClick={() => this.props.close(false)}>
                    {this.T('cancel')}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader) {
            loader = <Loader />;
        }

        const content = (
            <div>
                {loader}

                <TextField
                    field={this.state.form.label}
                    onInput={value => this.onInputValue('label', value)}
                    onBlur={() => this.validateField('label')}
                    label={this.T('label')}
                />

                <TextField
                    field={this.state.form.contractStart}
                    type='date'
                    onInput={value => this.onInputValue('contractStart', value)}
                    onBlur={() => this.validateField('contractStart')}
                    label={this.T('contractStart')}
                />

                <CheckBox
                    value={this.state.form.isTimeLimited.value}
                    onInput={value => this.onInputValue('isTimeLimited', value)}
                    label={this.T('timeLimited')}
                />

                {this.state.form.isTimeLimited.value ? (
                    <TextField
                        field={this.state.form.contractEnd}
                        type='date'
                        onInput={value => this.onInputValue('contractEnd', value)}
                        onBlur={() => this.validateField('contractEnd')}
                        label={this.T('contractEnd')}
                    />
                ) : null}
            </div>
        );
        return (
            <Modal
                title={this.T('title')}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default EditModal;
