import axios from 'axios';
import appConfig from '../appConfig.json';

import moment from 'moment';

export class AuthService {
    constructor(parent) {
        this.parent = parent;
    }

    isSessionActive() {
        if (!this.parent.state.session) {
            return false;
        }

        return true;
    }

    getRoleId = () => {
        return this.parent.state.session.roleId ? this.parent.state.session.roleId : 2;
    }

    tryToLogin(login, password) {
        return new Promise((resolve, reject) => {
            axios({
                url: appConfig.adminApiUrl + '/authorize/login',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    login: login,
                    password: password
                }
            })
                .then(response => {
                    if (response.data.code === 200) {
                        console.log(response.data);
                        this.parent.setState({ session: response.data.data });
                        this.saveSession();
                    } else {
                        console.log('login error');
                        console.log(response.data);
                        reject(response.data.error.systemMessage);
                    }
                })
                .catch(error => {
                    console.log(error);
                    console.log(error.response);
                    reject('Błąd logowania');
                });
        });
    }

    tryToLogout() {
        return new Promise((resolve, reject) => {
            if (
                !this.parent.state.session ||
                !this.parent.state.session.identityId ||
                !this.parent.state.session.refreshToken
            ) {
                this.parent.setState({ session: null });
                this.saveSession();
                reject('No active session');
                return;
            }

            axios({
                url: appConfig.adminApiUrl + '/authorize/logout',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    identityId: this.parent.state.session.identityId,
                    refreshToken: this.parent.state.session.refreshToken
                }
            })
                .then(() => {
                    this.parent.setState({ session: null });
                    this.saveSession();
                })
                .catch(error => {
                    console.log(error);
                    console.log(error.response);
                });
        });
    }

    loadSession() {
        try {
            let sessionData = window.localStorage.getItem('geAppSession');
            let session = JSON.parse(sessionData);
            return session;
        } catch {
            return null;
        }
    }

    saveSession() {
        window.localStorage.setItem(
            'geAppSession',
            JSON.stringify(this.parent.state.session)
        );
    }

    getAuthHeader() {
        return this.tryToRefreshSession().then(() => {
            return {
                GESessionId: this.parent.state.session.session.sessionId,
                GESessionToken: this.parent.state.session.session.sessionToken
            };
        });
    }

    tryToRefreshSession() {
        return new Promise((resolve, reject) => {
            if (
                !this.parent.state.session ||
                !this.parent.state.session.identityId ||
                !this.parent.state.session.refreshToken ||
                !this.parent.state.session.session
            ) {
                this.parent.setState({ session: null });
                this.saveSession();
                return reject('No active session');
            }

            if (
                moment().isAfter(this.parent.state.session.session.expireDate)
            ) {
                axios({
                    url: appConfig.adminApiUrl + '/authorize/refreshSession',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        identityId: this.parent.state.session.identityId,
                        refreshToken: this.parent.state.session.refreshToken
                    }
                })
                    .then(response => {
                        if (response.data.code === 200) {
                            console.log(response.data);
                            this.parent.setState(
                                prevState => {
                                    return {
                                        session: {
                                            ...prevState.session,
                                            session: response.data.data
                                        }
                                    };
                                },
                                () => {
                                    this.saveSession();
                                    resolve();
                                }
                            );
                        } else {
                            this.parent.setState({ session: null });
                            this.saveSession();
                            return reject('No active session');
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            } else {
                resolve();
            }
        });
    }
}
