import BasicFormComponent from '../../../../components/basicFormComponent/BasicFormComponent';
import React from 'react';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';
import axios from 'axios';

import Modal from '../../../../components/modal/Modal';
import Loader from '../../../../components/basic/Loader';

class BindingToModal extends BasicFormComponent {
    TObject = appTranslate.areas.catalogue.productNew.bindingModal;

    noBindingObject = {
        bindingTypeId: -1,
        label: this.TObject.noBinding,
        productTypeIdParent: -1,
        productTypeIdChild: -1
    };

    currentProductTypeId = -1;

    constructor(props) {
        super(props);

        this.state = {
            tree: [],
            types: [],
            loader: 0
        };
    }

    setLoader = value => {
        this.setState(prevState => {
            let l = prevState.loader + (value ? 1 : -1);
            if (l < 0) {
                l = 0;
            }
            return {
                loader: l
            };
        });
    };

    componentDidMount() {
        this.fetchTree();
        this.fetchBindingTypes();
    }

    fetchBindingTypes = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/catalogue/products/binding/list',
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            this.setLoader(false);

                            this.setState({
                                types: [this.noBindingObject, ...response.data.data]
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    fetchTree = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        appConfig.adminApiUrl +
                        '/catalogue/products/binding/treeAll/' +
                        this.props.params.productId +
                        (this.props.params.reverse ? '?reverse=true' : ''),
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            this.setLoader(false);
                            this.setState({
                                tree: response.data.data.map(c => {
                                    return {
                                        ...c,
                                        categories: c.categories.map(cc => {
                                            return {
                                                ...cc,
                                                products: cc.products.map(p => {
                                                    if (p.productId === this.props.params.productId) {
                                                        this.currentProductTypeId = p.productType.productTypeId;
                                                    }
                                                    if (p.binding) {
                                                        return p;
                                                    }
                                                    return {
                                                        ...p,
                                                        binding: this.noBindingObject
                                                    };
                                                })
                                            };
                                        })
                                    };
                                })
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    prepareData = () => {
        // return {
        //     label: this.getFieldL(this.state.form.label),
        //     isVisible: this.state.form.isVisible.value,
        //     categoryId: this.props.params.categoryId
        // };

        let bindings = [];

        this.state.tree.forEach(c => {
            c.categories.forEach(cc => {
                cc.products.forEach(p => {
                    if (p.binding.bindingTypeId > 0 && p.productId !== this.props.params.productId) {
                        bindings.push({ productId: p.productId, bindingTypeId: p.binding.bindingTypeId });
                    }
                });
            });
        });

        const response = {
            bindings: bindings,
            bindingReverse: this.props.params.reverse ? true : false,
            productId: parseInt(this.props.params.productId)
        };
        return response;
    };

    onSubmit = () => {
        this.submit();
    };

    submit = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/catalogue/products/binding/update',
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        params: this.prepareData()
                    }
                })
                    .then(response => {
                        // this.setLoader(false);
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    expandMainCategory = categoryId => {
        this.setState(prevState => {
            return {
                tree: prevState.tree.map(c => {
                    return {
                        ...c,
                        isOpen: c.categoryId === categoryId ? !c.isOpen : false
                    };
                })
            };
        });
    };

    expandChildCategory = (mainCategoryId, categoryId) => {
        this.setState(prevState => {
            return {
                tree: prevState.tree.map(c => {
                    if (c.categoryId !== mainCategoryId) {
                        return c;
                    }
                    return {
                        ...c,
                        categories: c.categories.map(cc => {
                            return {
                                ...cc,
                                isOpen: cc.categoryId === categoryId ? !cc.isOpen : false
                            };
                        })
                    };
                })
            };
        });
    };

    changeBindingType = (mainCategoryId, childCategoryId, productId, bindingTypeId) => {
        this.setState(prevState => {
            return {
                tree: prevState.tree.map(c => {
                    if (c.categoryId !== mainCategoryId) {
                        return c;
                    }
                    return {
                        ...c,
                        categories: c.categories.map(cc => {
                            if (cc.categoryId !== childCategoryId) {
                                return cc;
                            }
                            return {
                                ...cc,
                                products: cc.products.map(p => {
                                    if (p.productId !== productId) {
                                        return p;
                                    }
                                    return {
                                        ...p,
                                        binding: prevState.types.find(t => t.bindingTypeId === bindingTypeId)
                                    };
                                })
                            };
                        })
                    };
                })
            };
        });
    };

    changeCategoryBindingType = (mainCategoryId, childCategoryId, bindingTypeId) => {
        this.setState(prevState => {
            return {
                tree: prevState.tree.map(c => {
                    if (c.categoryId !== mainCategoryId) {
                        return c;
                    }
                    return {
                        ...c,
                        categories: c.categories.map(cc => {
                            if (cc.categoryId !== childCategoryId) {
                                return cc;
                            }
                            return {
                                ...cc,
                                products: cc.products.map(p => {
                                    return {
                                        ...p,
                                        binding: prevState.types.find(t => t.bindingTypeId === bindingTypeId)
                                    };
                                })
                            };
                        })
                    };
                })
            };
        });
    };

    render() {
        const footer = (
            <div className='space-between'>
                <button className='_button-light' onClick={() => this.onSubmit()}>
                    {this.T('save')}
                </button>

                <button className='_button-light' onClick={() => this.props.close(false)}>
                    {this.T('cancel')}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader > 0) {
            loader = <Loader />;
        }

        const content = (
            <div className='binding-modal__container'>
                {loader}
                {this.state.types.length > 0
                    ? this.state.tree.map(c => {
                          return (
                              <div className='binding-modal__main-category-wrapper' key={c.categoryId}>
                                  <div
                                      className='binding-modal__main-category'
                                      onClick={() => this.expandMainCategory(c.categoryId)}
                                  >
                                      <div className='binding-modal__main-category-icon'>
                                          {c.isOpen ? 'expand_less' : 'expand_more'}
                                      </div>
                                      <div className='binding-modal__main-category-label'>
                                          {this.T(c.label)}
                                          <span className='binding-modal__main-category-product-count'>
                                              {c.productCount}
                                          </span>
                                      </div>
                                  </div>
                                  {c.isOpen
                                      ? c.categories.map(cc => {
                                            let allProductSameType = true;
                                            let allProductType = null;

                                            let allProductSameBinding = true;
                                            let allProductBinding = null;

                                            const products = cc.isOpen
                                                ? cc.products.reduce((pt, p) => {
                                                      if (p.productId === this.props.params.productId) {
                                                          return pt;
                                                      }

                                                      if (allProductType === null) {
                                                          allProductType = p.productType.productTypeId;
                                                      } else {
                                                          if (
                                                              allProductSameType &&
                                                              allProductType !== p.productType.productTypeId
                                                          ) {
                                                              allProductSameType = false;
                                                              allProductType = -2;
                                                          }
                                                      }

                                                      if (allProductBinding === null) {
                                                          allProductBinding = p.binding.bindingTypeId;
                                                      } else {
                                                          if (
                                                              allProductSameBinding &&
                                                              allProductBinding !== p.binding.bindingTypeId
                                                          ) {
                                                              allProductSameBinding = false;
                                                              allProductBinding = -2;
                                                          }
                                                      }

                                                      const bindingTypeOptions = this.state.types.reduce((pr, t) => {
                                                          if (
                                                              t.bindingTypeId === -1 ||
                                                              (t.productTypeIdChild ===
                                                                  (this.props.params.reverse
                                                                      ? this.currentProductTypeId
                                                                      : p.productType.productTypeId) &&
                                                                  t.productTypeIdParent ===
                                                                      (this.props.params.reverse
                                                                          ? p.productType.productTypeId
                                                                          : this.currentProductTypeId))
                                                          ) {
                                                              pr.push(
                                                                  <option value={t.bindingTypeId} key={t.bindingTypeId}>
                                                                      {this.T(t.label)}
                                                                  </option>
                                                              );
                                                          }
                                                          return pr;
                                                      }, []);
                                                      pt.push(
                                                          <div className='binding-modal__product' key={p.productId}>
                                                              <div className='binding-modal__product-label'>
                                                                  {this.T(p.productName)}
                                                              </div>
                                                              <div className='binding-modal__product-number'>
                                                                  {p.ean}
                                                              </div>
                                                              <div className='binding-modal__product-binding'>
                                                                  <select
                                                                      value={p.binding.bindingTypeId}
                                                                      onChange={event =>
                                                                          this.changeBindingType(
                                                                              c.categoryId,
                                                                              cc.categoryId,
                                                                              p.productId,
                                                                              parseInt(event.target.value)
                                                                          )
                                                                      }
                                                                  >
                                                                      {bindingTypeOptions}
                                                                  </select>
                                                              </div>
                                                          </div>
                                                      );

                                                      return pt;
                                                  }, [])
                                                : null;

                                            let categoryChangeBinding = null;

                                            if (
                                                allProductType != null &&
                                                allProductBinding != null &&
                                                allProductSameType
                                            ) {
                                                const categoryChangeOptions = this.state.types.reduce(
                                                    (pr, t) => {
                                                        if (
                                                            (t.productTypeIdChild ===
                                                                (this.props.params.reverse
                                                                    ? this.currentProductTypeId
                                                                    : allProductType) &&
                                                                t.productTypeIdParent ===
                                                                    (this.props.params.reverse
                                                                        ? allProductType
                                                                        : this.currentProductTypeId)) ||
                                                            t.bindingTypeId === -1
                                                        ) {
                                                            pr.push(
                                                                <option value={t.bindingTypeId} key={t.bindingTypeId}>
                                                                    {this.T(t.label)}
                                                                </option>
                                                            );
                                                        }
                                                        return pr;
                                                    },
                                                    [
                                                        <option disabled={true} value={-2} key={-2}>
                                                            {this.T('manyBinding')}
                                                        </option>
                                                    ]
                                                );
                                                categoryChangeBinding = (
                                                    <div className='binding-modal__child-category__change-binding'>
                                                        <select
                                                            value={allProductBinding}
                                                            onChange={event =>
                                                                this.changeCategoryBindingType(
                                                                    c.categoryId,
                                                                    cc.categoryId,
                                                                    parseInt(event.target.value)
                                                                )
                                                            }
                                                        >
                                                            {categoryChangeOptions}
                                                        </select>
                                                    </div>
                                                );
                                            }

                                            return (
                                                <div
                                                    className='binding-modal__child-category-wrapper'
                                                    key={cc.categoryId}
                                                >
                                                    <div className='binding-modal__child-category'>
                                                        <div
                                                            className='binding-modal__child-category-icon'
                                                            onClick={() =>
                                                                this.expandChildCategory(c.categoryId, cc.categoryId)
                                                            }
                                                        >
                                                            {cc.isOpen ? 'expand_less' : 'expand_more'}
                                                        </div>
                                                        <div
                                                            className='binding-modal__child-category-label'
                                                            onClick={() =>
                                                                this.expandChildCategory(c.categoryId, cc.categoryId)
                                                            }
                                                        >
                                                            {this.T(cc.label)}
                                                            <span className='binding-modal__child-category-product-count'>
                                                                {cc.productCount}
                                                            </span>
                                                        </div>
                                                        {categoryChangeBinding}
                                                    </div>
                                                    {products}
                                                </div>
                                            );
                                        })
                                      : null}
                              </div>
                          );
                      })
                    : null}
            </div>
        );
        return (
            <Modal
                title={this.T('title')}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default BindingToModal;
