import React, { Component } from 'react';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';
import Loader from '../../../../components/basic/Loader';
import { AppContext } from '../../../../services/context';
import axios from 'axios';
import moment from 'moment';

class StockDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: true,
            product: {},
            add: {
                modal: false
            }
        };
    }

    componentDidMount() {
        this.getDetails();
    }

    T = (prop, params, r) => {
        let p;

        if (typeof prop === 'object') {
            p = prop;
        }
        if (typeof prop === 'string') {
            p = prop
                .split('.')
                .reduce(
                    (obj, a) => obj[a],
                    appTranslate.areas.magazine.stock.details
                );
        }
        if (r) {
            return this.context.translateService.getTranslationR(p, params);
        }
        return this.context.translateService.getTranslation(p, params);
    };

    setLoaderState = state => {
        this.setState({ loader: state });
    };

    getDetails = () => {
        console.log(this.props);
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        appConfig.adminApiUrl +
                        '/magazine/stock/details/' +
                        this.props.match.params.productId,
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState({
                                product: response.data.data,
                                loader: false
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoaderState(false);
            });
    };

    render() {
        let loader = <div />;
        if (this.state.loader) {
            loader = <Loader />;
        }

        if (this.state.loader) {
            return <div className='content-basic'>{loader}</div>;
        }

        const rows = this.state.product.quantityList.map((e, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                        {e.expireDate
                            ? moment(e.expireDate).format('Y-MM-DD')
                            : '-'}
                    </td>
                    <td>{e.quantity}</td>
                </tr>
            );
        });

        return (
            <div className='content-basic'>
                <div className='content-basic__header'>
                    <div className='product-details'>
                        <div className='product-details__row'>
                            <div className='product-details__name'>
                                <div className='product-details__name-main'>
                                    {this.state.product.product.ean}
                                </div>
                                <div className='product-details__name-second'>
                                    EAN
                                </div>
                            </div>
                        </div>

                        <div className='product-details__row'>
                            <div className='product-details__name'>
                                <div className='product-details__name-main'>
                                    {this.T(this.state.product.product.name)}
                                </div>
                                <div className='product-details__name-second'>
                                    {this.T(
                                        this.state.product.product.name,
                                        null,
                                        true
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content-basic__content'>
                    <div className='table-wrapper'>
                        <table>
                            <thead>
                                <tr>
                                    <td>{this.T('no')}</td>
                                    <td>{this.T('expireDate')}</td>
                                    <td>{this.T('quantity')}</td>
                                    <td />
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
StockDetails.contextType = AppContext;

export default StockDetails;
