import React, { Component } from 'react';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';
import Loader from '../../../../components/basic/Loader';
import { AppContext } from '../../../../services/context';
import axios from 'axios';

import AddModal from './AddModal';
import EditModal from './EditModal';
import DeleteModal from './DeleteModal';

class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            tree: [],
            modal: {
                add: {
                    open: false,
                    params: null
                },
                edit: {
                    open: false,
                    params: null
                },
                delete: {
                    open: false,
                    params: null
                }
            }
        };
    }

    componentDidMount() {
        this.getTree();
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(prop, params, r, appTranslate.areas.catalogue.category.list);
    };

    setLoaderState = state => {
        this.setState({ loader: state });
    };

    getTree = () => {
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/catalogue/category/tree',
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState({ tree: response.data.data });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    onModalClose = (modal, response) => {
        this.modalState(modal, false, null);
        if (response) {
            this.getTree();
        }
    };

    switchMainCategory = categoryId => {
        this.setState(prevState => {
            return {
                tree: prevState.tree.map(c => {
                    return {
                        ...c,
                        isOpen: c.categoryId === categoryId ? !c.isOpen : false
                    };
                })
            };
        });
    };

    render() {
        let loader = <div />;
        if (this.state.loader) {
            loader = <Loader />;
        }

        let addModal = <div />;
        if (this.state.modal.add.open) {
            addModal = <AddModal close={response => this.onModalClose('add', response)} params={this.state.modal.add.params} />;
        }

        let editModal = <div />;
        if (this.state.modal.edit.open) {
            editModal = <EditModal close={response => this.onModalClose('edit', response)} params={this.state.modal.edit.params} />;
        }

        let deleteModal = <div />;
        if (this.state.modal.delete.open) {
            deleteModal = <DeleteModal close={response => this.onModalClose('delete', response)} params={this.state.modal.delete.params} />;
        }

        return (
            <div className='content-basic'>
                {loader}
                {addModal}
                {editModal}
                {deleteModal}
                <div className='content-basic__header'>
                    <button className='_button-light' onClick={() => this.modalState('add', true, { mainCategoryId: 0 })}>
                        {this.T('addButton')}
                    </button>
                </div>
                <div className='content-basic__content'>
                    <div className='category-tree'>
                        {this.state.tree.reduce((p, e) => {
                            p.push(
                                <div className='category-tree__wrapper' key={e.categoryId}>
                                    <div className='category-tree__main-category'>
                                        <div
                                            className='category-tree__main-category__switch'
                                            onClick={() => {
                                                this.switchMainCategory(e.categoryId);
                                            }}
                                        >
                                            {e.isOpen ? 'expand_less' : 'expand_more'}
                                        </div>
                                        <div
                                            className='category-tree__main-category__label'
                                            onClick={() => {
                                                this.switchMainCategory(e.categoryId);
                                            }}
                                        >
                                            {this.T(e.label)}
                                        </div>

                                        <div className='category-tree__main-category__count'>
                                            {this.T('table.categoryCount')}: {e.categories.length}
                                        </div>

                                        <div className='category-tree__main-category__count'>
                                            {this.T('table.productCount')}: {e.productCount}
                                        </div>

                                        <div className='category-tree__main-category__buttons'>
                                            <button
                                                className='_button-light small'
                                                onClick={() =>
                                                    this.modalState('add', true, {
                                                        mainCategoryId: e.categoryId
                                                    })
                                                }
                                            >
                                                {this.T('table.addSubcategory')}
                                            </button>
                                            <button
                                                className='_button-light small'
                                                onClick={() =>
                                                    this.modalState('edit', true, {
                                                        categoryId: e.categoryId
                                                    })
                                                }
                                            >
                                                {this.T('table.edit')}
                                            </button>
                                            <button
                                                className={`_button-light small ${e.categories.length > 0 ? 'disabled' : ''}`}
                                                onClick={() => {
                                                    if (e.categories.length > 0) {
                                                        return;
                                                    }
                                                    this.modalState('delete', true, {
                                                        categoryId: e.categoryId
                                                    });
                                                }}
                                                title={e.categories.length > 0 ? this.T('table.deleteDisabledReason') : ''}
                                            >
                                                {this.T('table.delete')}
                                            </button>
                                        </div>
                                    </div>
                                    {e.isOpen
                                        ? e.categories.map(c => {
                                              return (
                                                  <div className='category-tree__child-category' key={c.categoryId}>
                                                      <div className='category-tree__child-category__label'>{this.T(c.label)}</div>
                                                      <div className='category-tree__child-category__count'>
                                                          {this.T('table.productCount')}: {c.productCount}
                                                      </div>
                                                      <div className='category-tree__child-category__buttons'>
                                                          <button
                                                              className='_button-light small'
                                                              onClick={() =>
                                                                  this.modalState('edit', true, {
                                                                      categoryId: c.categoryId
                                                                  })
                                                              }
                                                          >
                                                              {this.T('table.edit')}
                                                          </button>
                                                          <button
                                                              className={`_button-light small ${c.productCount > 0 ? 'disabled' : ''}`}
                                                              onClick={() => {
                                                                  if (c.productCount > 0) {
                                                                      return;
                                                                  }
                                                                  this.modalState('delete', true, {
                                                                      categoryId: c.categoryId
                                                                  });
                                                              }}
                                                              title={c.productCount > 0 ? this.T('table.deleteDisabledReason2') : ''}
                                                          >
                                                              {this.T('table.delete')}
                                                          </button>
                                                      </div>
                                                  </div>
                                              );
                                          })
                                        : null}
                                </div>
                            );
                            return p;
                        }, [])}
                    </div>
                </div>
            </div>
        );
    }
}
List.contextType = AppContext;

export default List;
