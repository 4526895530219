import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import appConfig from '../../../../appConfig.json';
import appTranslate from '../../../../appTranslate.json';
import Loader from '../../../../components/basic/Loader';
import { AppContext } from '../../../../services/context';
import axios from 'axios';

import EditModal from './CompanyEditModal';
import DeleteModal from './CompanyDeleteModal';

import AddDestinationModal from './DestinationAddModal';
import EditDestinationModal from './DestinationEditModal';
import DeleteDestinationModal from './DestinationDeleteModal';

class Details extends Component {
    constructor(props) {
        super(props);

        this.companyId = this.props.match.params.companyId;

        this.state = {
            loader: false,
            company: null,
            productTree: [],
            openTree: {
                main: null,
                secondary: null
            },
            loaders: {
                productTree: false
            },
            modal: {
                edit: {
                    open: false,
                    params: null
                },
                delete: {
                    open: false,
                    params: null
                },
                addDestination: {
                    open: false,
                    params: null
                },
                editDestination: {
                    open: false,
                    params: null
                },
                deleteDestination: {
                    open: false,
                    params: null
                }
            }
        };
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(
            prop,
            params,
            r,
            appTranslate.areas.company.company.details
        );
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    onModalClose = (modal, response) => {
        this.modalState(modal, false, null);

        if (modal === 'delete') {
            if (response) {
                window.location = '/company/company';
            }
            return;
        }

        if (response) {
            this.getData();
            if (this.state.productTree.length !== 0) {
                this.getBindedProducts();
            }
        }
    };

    componentDidMount() {
        this.getData();
    }

    setLoaderState = state => {
        this.setState({ loader: state });
    };

    setLoader = (loader, state) => {
        this.setState(prev => {
            return {
                loaders: {
                    ...prev.loaders,
                    [loader]: state
                }
            };
        });
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    openTree = (level, id) => {
        this.setState(prev => {
            return {
                openTree: {
                    ...prev.openTree,
                    [level]: prev.openTree[level] === id ? null : id
                }
            };
        });
    };

    getData = () => {
        console.log(this.props.match.params.fileId);
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: appConfig.adminApiUrl + '/company/company/details/' + this.props.match.params.companyId,
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState({ company: response.data.data });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    render() {
        let loader = <div />;
        if (this.state.loader) {
            loader = <Loader />;
        }

        if (!this.state.company) {
            return <div className='content-basic'>{loader}</div>;
        }

        let editModal = <div />;
        if (this.state.modal.edit.open) {
            editModal = (
                <EditModal
                    close={response => this.onModalClose('edit', response)}
                    params={this.state.modal.edit.params}
                />
            );
        }

        let deleteModal = <div />;
        if (this.state.modal.delete.open) {
            deleteModal = (
                <DeleteModal
                    close={response => this.onModalClose('delete', response)}
                    params={this.state.modal.delete.params}
                />
            );
        }

        let addDestinationModal = <div />;
        if (this.state.modal.addDestination.open) {
            deleteModal = (
                <AddDestinationModal
                    close={response => this.onModalClose('addDestination', response)}
                    params={this.state.modal.addDestination.params}
                />
            );
        }

        let editDestinationModal = <div />;
        if (this.state.modal.editDestination.open) {
            editDestinationModal = (
                <EditDestinationModal
                    close={response => this.onModalClose('editDestination', response)}
                    params={this.state.modal.editDestination.params}
                />
            );
        }

        let deleteDestinationModal = <div />;
        if (this.state.modal.deleteDestination.open) {
            deleteDestinationModal = (
                <DeleteDestinationModal
                    close={response => this.onModalClose('deleteDestination', response)}
                    params={this.state.modal.deleteDestination.params}
                />
            );
        }

        return (
            <div className='content-basic'>
                {loader}
                {editModal}
                {deleteModal}
                {addDestinationModal}
                {editDestinationModal}
                {deleteDestinationModal}
                <div className='content-basic__header'>
                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('edit', true, {
                                id: this.companyId
                            })
                        }
                    >
                        {this.T('editCompany')}
                    </button>

                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('delete', true, {
                                id: this.companyId
                            })
                        }
                    >
                        {this.T('deleteCompany')}
                    </button>

                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('addDestination', true, {
                                id: this.companyId
                            })
                        }
                    >
                        {this.T('addDestination')}
                    </button>
                </div>
                <div className='content-basic__header'>
                    <div className='product-details'>
                        <div className='product-details__row'>
                            <div className='product-details__name'>
                                <div className='product-details__name-main'>{this.state.company.companyName}</div>
                                <div className='product-details__name-second'>{this.T('companyName')}</div>
                            </div>
                        </div>
                        <div className='product-details__row'>
                            <div className='product-details__name'>
                                <div className='product-details__name-main'>{this.state.company.nip}</div>
                                <div className='product-details__name-second'>{this.T('nip')}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='content-basic__header'>
                    <div className='product-details'>
                        <div className='product-details__row'>
                            <div className='product-details__property-group'>{this.T('destinations')}</div>
                        </div>

                        <div className='product-details__row'>
                            {this.state.company.destinations.length === 0 ? (
                                <div className=''>{this.T('noDestinations')}</div>
                            ) : (
                                this.state.company.destinations.map(d => (
                                    <div className='details-tree__main' key={d.destinationId}>
                                        <div
                                            className='details-tree__main-content'
                                            onClick={() => this.openTree('main', d.destinationId)}
                                        >
                                            <div className='details-tree__main-expand-icon'>
                                                {this.state.openTree.main === d.destinationId
                                                    ? 'expand_less'
                                                    : 'expand_more'}
                                            </div>
                                            <div className='details-tree__main-label'>{d.label}</div>
                                            <div className='details-tree__main-buttons'>
                                                <button
                                                    className='_button-light small'
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        this.modalState('editDestination', true, {
                                                            id: d.destinationId
                                                        })
                                                    }}
                                                >
                                                    {this.T('editDestination')}
                                                </button>

                                                <button
                                                    className='_button-light small'
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        this.modalState('deleteDestination', true, {
                                                            id: d.destinationId
                                                        })
                                                    }}
                                                >
                                                    {this.T('deleteDestination')}
                                                </button>
                                            </div>
                                        </div>
                                        {this.state.openTree.main === d.destinationId ? (
                                            <div className='details-tree__child'>
                                                <div className='details-tree__child-row'>
                                                    <div className='details-tree__child-row-title'>
                                                        {this.T('country')}:
                                                    </div>
                                                    <div className='details-tree__child-row-value'>{d.country}</div>
                                                </div>
                                                <div className='details-tree__child-row'>
                                                    <div className='details-tree__child-row-title'>
                                                        {this.T('postalCode')}:
                                                    </div>
                                                    <div className='details-tree__child-row-value'>{d.postalCode}</div>
                                                </div>

                                                <div className='details-tree__child-row'>
                                                    <div className='details-tree__child-row-title'>
                                                        {this.T('region')}:
                                                    </div>
                                                    <div className='details-tree__child-row-value'>{d.region}</div>
                                                </div>
                                                <div className='details-tree__child-row'>
                                                    <div className='details-tree__child-row-title'>
                                                        {this.T('city')}:
                                                    </div>
                                                    <div className='details-tree__child-row-value'>{d.city}</div>
                                                </div>
                                                <div className='details-tree__child-row'>
                                                    <div className='details-tree__child-row-title'>
                                                        {this.T('street')}:
                                                    </div>
                                                    <div className='details-tree__child-row-value'>{d.street}</div>
                                                </div>
                                                <div className='details-tree__child-row'>
                                                    <div className='details-tree__child-row-title'>
                                                        {this.T('houseNumber')}:
                                                    </div>
                                                    <div className='details-tree__child-row-value'>{d.houseNumber}</div>
                                                </div>
                                                <div className='details-tree__child-row'>
                                                    <div className='details-tree__child-row-title'>
                                                        {this.T('extraInfo')}:
                                                    </div>
                                                    <div className='details-tree__child-row-value'>{d.extraInfo}</div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Details.contextType = AppContext;

export default Details;
